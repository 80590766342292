<template>
    <div
        v-if="ranks && ranks.length > 0"
        class="leaderboard-container"
    >
        <div align-h="start" class="header-top pb-2 ml-3 mr-3">
            <span class="text-profile-title-first vertical-align mr-2">{{ $t('leaderBoard.headerFirstText') }}</span>
            <span v-if="hasPlayerPlayed" class="text-profile-title-second vertical-align">
                {{ $t('leaderBoard.headerSecondText', {totalPlayers}) }}
            </span>
            <span v-else class="text-profile-title-second vertical-align">
                {{ $t('leaderBoard.notPlayedYet') }}
            </span>
        </div>
        <div class="leaderboard-info-container">
            <span v-if="currentPlayerPosition" class="congratulation-text">
                <span v-if="totalPoints !== 0">{{ $t('leaderBoard.congratulationText') }} </span>
                <span>{{ $t('leaderBoard.rankingText') }} </span>
                <span class="ranking">{{ currentPlayerPosition }}</span>!
            </span>
            <div class="star-points">
                <span class="points-text">{{ totalPoints }}</span>
                <font-awesome-icon :icon="[ 'fas', 'star' ]" class="star points-text header-star" />
            </div>
        </div>
        <div class="table-container">
            <b-table
                responsive
                :dark="dark"
                :items="ranks"
                :fields="fields"
                :tbody-tr-class="rowClass"
            >
                <template #cell(rank)="data">
                    {{ data.item.rank }}
                </template>
                <template
                    #cell(avatar.imageUrl)="data"
                >
                    <avatar :avatar="data.item.avatar? data.item.avatar : null" avatar-width="45px" />
                </template>
                <template #cell(playerBadges)="data">
                    <badge
                        class="leaderboard-badge"
                        :badges="data.item.playerBadges"
                    />
                </template>
                <template #cell(totalScore)="data">
                    <div class="points-cell">
                        <span class="pr-1">{{ data.item.totalScore }}</span>
                        <font-awesome-icon :icon="[ 'fas', 'star' ]" class="star table-points-icon" />
                    </div>
                </template>
                <template #cell(totalRedeemedPoints)="data">
                    <div class="points-cell">
                        <font-awesome-icon :icon="[ 'fas', 'star' ]" class="star table-points-icon" />
                        <span class="pl-1">{{ data.item.totalRedeemedPoints }}</span>
                    </div>
                </template>
            </b-table>

        </div>
    </div>
</template>

<script>
    import config from '@/mixins/config'
    import Badge from '@/components/Badge'
    import Avatar from '@/components/Avatar'

    export default {
        name: 'LeaderBoard',
        components: { Badge, Avatar },
        mixins: [ config ],
        data() {
            return {
                dark: true
            }
        },
        computed: {
            featureConfig() {
                return this.$store.getters.getConfigFeatures
            },
            player() {
                return this.$store.getters.getCurrentUser
            },
            totalPoints() {
                return this.player.totalScore
            },
            totalMissionCount() {
                return this.missions.length
            },
            missions() {
                return this.$store.getters.getMissionInfoList
            },
            ranks() {
                return this.$store.getters.getRanks
            },
            totalPlayers() {
                return this.$store.getters.getTotalPlayers
            },
            hasPlayerPlayed() {
                return this.ranks.find(rank => rank.id === this.player.id)
            },
            currentPlayerPosition() {
                if (this.ranks.find(rank => rank.id === this.player.id)) {
                    return this.ranks.find(rank => rank.id === this.player.id).rank
                }
                return null
            },
            fields() {
                const fields = [
                    { key: 'rank', label: this.$t('leaderBoard.position') },
                    { key: 'avatar.imageUrl', label: this.$t('leaderBoard.avatar') },
                    { key: 'name', label: this.$t('leaderBoard.name') },
                    { key: 'playerBadges', label: this.$t('leaderBoard.badges') },
                    { key: 'totalScore', label: this.$t('leaderBoard.points') }
                ]

                if (this.featureConfig.rewardsEnabled) {
                    fields.push({ key: 'totalRedeemedPoints', label: this.$t('leaderBoard.redeemedPoints') })
                }
                return fields
            }
        },
        methods: {
            rowClass(item, type) {
                let classes = ''
                if (item && type === 'row') {
                    let index = this.ranks.indexOf(item)
                    if (index <= 4) {
                        classes += 'top-rank '
                    }
                    if (item.id === this.player.id) {
                        classes += 'current-player '
                    }
                }
                return classes
            }
        }
    }
</script>

<style lang="scss" scoped>
.leaderboard-container {
    padding: 1rem 0 2rem;
    width: 100%;
}

.leaderboard-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    gap: 0.5rem;
    text-align: left;
}

.star-points {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.header-top {
    padding-left: 0;
    text-align: left;
    color: var(--main-content-text);
    border-bottom: 2px solid var(--main-content-text);
}

.text-profile-title-first {
    font-weight: 700;
    font-size: 1.5rem;
}

.text-profile-title-second {
    font-weight: 300;
    font-size: 1rem;
}

.congratulation-text {
    font-size: 1.3rem;
    vertical-align: middle;
    color: var(--main-content-text);
}

.ranking {
    color: var(--main-content-text);
    font-weight: bold;
}

.points-text {
    font-weight: 700;
    font-size: 2rem;
    color: var(--main-content-text);
}

.table-points-icon {
    font-weight: 700;
    font-size: 20px;
    vertical-align: middle;
}

.table-points-icon path {
    color: var(--star-color) !important;
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.points-cell {
    display: flex;
    justify-content: center;
    align-items: center;
}

.star {
    transform: translateY(-10%);
}

.header-star {
    transform: translateY(-5px);
}

svg.star path {
    color: var(--star-color);
}

@media screen and (min-width: 768px) {
    .table-container {
        padding: 0 1rem;
    }
}

@media screen and (min-width: 992px) {
    .leaderboard-info-container {
        padding: 1.5rem 2rem;
    }

    .congratulation-text {
        font-size: 1.5rem;
    }

    .points-text {
        font-size: 2.5rem;
    }
}
</style>

<style lang="scss">
    .leaderboard-container .table-dark th {
        background: var(--info-color);
        font-size: 1.1rem;
    }

    .leaderboard-container .table-dark td {
        background: var(--white);
        font-weight: 700;
        font-size: 1.3rem;
        color: var(--primary-color);
    }

    .leaderboard-container .leaderboard-badge img {
        width: 100%;
        max-width: 50px;
        min-width: 50px;
    }

    .leaderboard-container .table thead th {
        vertical-align: middle;
    }

    .leaderboard-container .table > tbody > tr {
        height: 76px;
    }

    .leaderboard-container .table > tbody > tr > td {
        vertical-align: middle;
    }

    .leaderboard-container .table-dark .top-rank td {
        background: var(--light-secondary-color);
    }

    .leaderboard-container .table-dark .current-player td {
        background: var(--secondary-color);
        color: var(--white);
    }

    .leaderboard-container .table-striped > tbody > tr:nth-child(2n+1) > td,
    .leaderboard-container .table-striped > tbody > tr:nth-child(2n+1) > th {
        background-color: var(--white);
    }

</style>
